import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Head from '@alweb-merkator/shared/components/Head';
import { Styles } from './styles';

const CupomBeneficios = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.location.href =
      'https://www.sicc.com.br/files/2023/cupom-de-descontos-sicc.pdf';
  }, []);

  return (
    <div className="pagina-protocolo">
      <Styles />
      <Head
        title={t('paginas.traslado.titulo')}
        description={t('paginas.traslado.titulo')}
      />
    </div>
  );
};

export default CupomBeneficios;
