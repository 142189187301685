import { createGlobalStyle } from 'styled-components';
export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuItem,
  SecaoBotaoVoltar,
} from '@alweb-merkator/shared/components/Secao';
export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export const Styles = createGlobalStyle`
.pagina-traslado {
  h2 {
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.textInBg};
    background: ${(props) => props.theme.colors.primary};
    padding: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  /* .container-valores:after {
    content: '';
    display: flex;
    clear: both;
    text-align: center;
  } */

  .container-tickets {
    text-align: left;
    display: inline-block;
    min-height: 100%;
    &:after {
      content: '';
      clear: both;
    }
  }

  .box-ticket {
    padding: 15px;
    text-align: center;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.textInBg};
    margin-bottom: 20px;
    &.bg-secondary {
      background-color: ${(props) => props.theme.colors.secondary} !important;
    }

    h2 {
      font-size: 20px;
      margin: 0;
      background: initial;
    }

    .imagem {
      display: inline-block;
      width: 100%;
      height: 100px;
      background-color: green;
      margin-bottom: 15px;
    }

    p {
      font-size: 17px;
      margin-bottom: 15px;
    }

    .texto {
      min-height: 100px;  
    }

    p.price {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: bold;
    }

    .font-size-11px{font-size: 11px}
    .min-height-box-ticket{min-height: 454px}
  }

  .container-button{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 100px;
  }

  .box-comprar {
    display: inline-block;
    background-color: #29017C;
    width: 260px;
    padding: 20px 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .box-comprar a{
    color: #FFFFFF;
  }

  .max-height-60{max-height: 60px}
  .font-size-11px{font-size: 11px}
  .min-height-box-ticket{min-height: 454px}
}
`;
