import { Link } from 'react-router-dom';
import styled from 'styled-components';

export { Col, Row } from 'reactstrap';

export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '@alweb-merkator/shared/components/Secao';

export const Conteudo = styled.div`
  min-height: 40vh;
  @media (max-width: 992px) {
    min-height: 20vh;
  }
`;

export const ImagemStyled = styled.img`
  width: 150px;

  @media (min-width: 992px) {
    width: 200px;
  }

  @media (min-width: 1200px) {
    width: 250px;
  }
`;

export const Grid = styled(Link)`
  width: 100%;
  height: 40vh;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const GridItem = styled.div`
  height: 100%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  width: calc(50% - 5px);
`;
