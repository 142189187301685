import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import blNewsService from '@alweb-merkator/shared/services/blNewsService';
import imageConecTech00 from '../../../../assets/image-ConecTech-00.png';
import imageConecTech01 from '../../../../assets/image-ConecTech-01.png';

import {
  Tradutor,
  Secao,
  SecaoCabecalho,
  SecaoTitulo,
  Conteudo,
  Grid,
  GridItem1,
  GridItem2,
} from './styles';

const SecaoConectech = ({ idPage, api, ...props }) => {
  const { t } = useTranslation();
  const secaoId = t('slugs.onnoPlace');
  const { lang } = useParams();
  const { getPaginas } = blNewsService();
  const [conectech, setConectech] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGet();
    return function cleanup() {};
  }, []);

  const _handleGet = async () => {
    await getPaginas(idPage).then(({ items }) => {
      const dado = items && items.length ? items[0] : {};
      setConectech(dado);
      setIsLoading(true);
    });
  };
  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>
          <Tradutor path={isLoading ? conectech.title : ''} />
        </SecaoTitulo>
      </SecaoCabecalho>

      <Conteudo
        className="mb-4 text-justify"
        dangerouslySetInnerHTML={{
          __html: isLoading ? conectech.content : '',
        }}
      />

      <Grid to={`/${lang}/${t('slugs.galeriaDeImagens')}`}>
        <GridItem1
          style={{
            backgroundImage: `url(${imageConecTech00})`,
          }}
        />
        <GridItem2
          className="mr-0"
          style={{
            backgroundImage: `url(${imageConecTech01})`,
          }}
        />
      </Grid>
    </Secao>
  );
};
SecaoConectech.propTypes = {
  idPage: PropTypes.any,
  api: PropTypes.any,
};
export default SecaoConectech;
