import React from 'react';
import api from '@alweb-merkator/shared/services/api';

import SecaoImprensa from '@alweb-merkator/shared/pages/PaginaInicial/SecaoImprensa';
import SecaoEMerkator from '@alweb-merkator/shared/pages/PaginaInicial/SecaoEMerkator';
import SecaoPlantaDaFeira from '@alweb-merkator/shared/pages/PaginaInicial/SecaoPlantaDaFeira';
import SecaoExpositoresAtiva from '@alweb-merkator/shared/pages/PaginaInicial/SecaoExpositoresAtiva';
import SecaoAgenciaDeTurismo from '@alweb-merkator/shared/pages/PaginaInicial/SecaoAgenciaDeTurismo';

import SecaoBanner from './SecaoBanner';
import SecaoGramado from './SecaoGramado';
import SecaoGrupoDeImportadores from './SecaoGrupoDeImportadores';
import SecaoParceiros from './SecaoParceiros';
import SecaoSerraPark from './SecaoSerraPark';
import SecaoSicc from './SecaoSicc';
import SecaoQueroVisitar from './SecaoQueroVisitar';

import ImagemPLanta from '../../assets/planta-da-feira.png';
import SecaoEstacao3Conectech from './SecaoEstacao3Conectech';
import AlertModal from '@alweb-merkator/shared/components/AlertModal';

const PaginaInicial = () => (
  <>
    <SecaoBanner />
    <SecaoSicc idPage={461} api={api} />
    <SecaoGramado idPage={13} api={api} />
    <SecaoSerraPark idPage={21} api={api} />
    <SecaoParceiros idPage={18} api={api} />
    <SecaoGrupoDeImportadores idPage={30} api={api} />
    <SecaoEstacao3Conectech />
    <SecaoQueroVisitar />
    <SecaoAgenciaDeTurismo idPage={15} />
    <SecaoExpositoresAtiva showCategory={false} />
    <SecaoPlantaDaFeira
      idPage={14}
      imagemPlanta={ImagemPLanta}
      showPlantaBaixa={false}
    />
    <SecaoEMerkator contemProgramacao={false} />
    <SecaoImprensa />
    <AlertModal
      imageDescription="Nova data do Salão Internacional do Couro e do Calçado"
      urlImage="#"
      urlTitle="Nova data do Salão Internacional do Couro e do Calçado"
      imageModal="http://paineldoexpositor.com.br/uploads/media/lg/media_666175a8698b22_21041494.jpg"
      showModal={false}
    />
  </>
);

export default PaginaInicial;
