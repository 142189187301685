import React from 'react';
import ThemeProviderShared from '@alweb-merkator/shared/providers/ThemeProvider';

import siccTheme from '../styles/themes/sicc';

const ThemeProvider = (props) => (
  <ThemeProviderShared currentTheme={siccTheme} {...props} />
);

export default ThemeProvider;
