import styled from 'styled-components';

export { default as BannerInternas } from '@alweb-merkator/shared/components/BannerInternas';

export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '@alweb-merkator/shared/components/Secao';

export const Conteudo = styled.div``;
