// import React from 'react';
// import { useTranslation } from 'react-i18next';

// import Head from '@alweb-merkator/shared/components/Head';
// import SecaoQueroVisitar from '@alweb-merkator/shared/pages/PaginaInicial/SecaoQueroVisitarOff';
// import iconeCredenciamento from '../../../zero-grau/src/assets/icone-credenciamento.png';

// import {
//   Styles,
//   Secao,
//   Tradutor,
//   SecaoMenu,
//   SecaoCabecalho,
//   SecaoTitulo,
//   SecaoMenuNav,
//   SecaoMenuItem,
//   SecaoMenuLista,
//   SecaoBotaoVoltar,
// } from './styles';
// import { Redirect } from 'react-router';

const QueroVisitar = () => {
  // const { t } = useTranslation();

  return window.location.replace(
    'https://sigevent.pro/merkator/visitantes/formularios.php?id_edicao=205'
  );
  // <Redirect
  //   location={
  //     'https://sigevent.pro/merkator/visitantes/formularios.php?id_edicao=205'
  //   }
  // />
  // <div className="pagina-traslado">
  //   <Styles />
  //   <Head
  //     title={t('paginas.inicial.secaoQueroVisitar.credenciamento.titulo')}
  //     description={t(
  //       'paginas.inicial.secaoQueroVisitar.credenciamento.conteudo'
  //     ).replace(/(<([^>]+)>)/gi, '')}
  //   />
  //   <Secao className="mb-4">
  //     <SecaoCabecalho className="mb-5">
  //       <SecaoTitulo>
  //         <Tradutor path="paginas.inicial.secaoQueroVisitar.credenciamento.titulo" />
  //       </SecaoTitulo>
  //       <SecaoMenu>
  //         <SecaoMenuNav>
  //           <SecaoMenuLista>
  //             <SecaoMenuItem></SecaoMenuItem>
  //             <SecaoMenuItem>
  //               <SecaoBotaoVoltar />
  //             </SecaoMenuItem>
  //           </SecaoMenuLista>
  //         </SecaoMenuNav>
  //       </SecaoMenu>
  //     </SecaoCabecalho>
  //   </Secao>
  //   <div className="container-valores">
  //     <div className="row">
  //       <SecaoQueroVisitar
  //         iconeCredenciamento={iconeCredenciamento}
  //         showTitle={false}
  //       />
  //     </div>
  //   </div>
  // </div>
};

export default QueroVisitar;
