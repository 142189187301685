import styled from 'styled-components';

import Banner from '../../components/Banner';
import {
  SecaoMenuItem as CompSecaoMenuItem,
  SecaoBotaoVoltar as CompSecaoBotaoVoltar,
} from '../../components/Secao';

export { HashLink } from 'react-router-hash-link';

export { FaPlus as PlusIcon } from 'react-icons/fa';

export { Link } from 'react-router-dom';

export { Col, Row } from 'reactstrap';

export { default as ImageCover } from '../../components/ImageCover';

export { Tradutor } from '../../components/I18n';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../components/Secao';

export const SecaoMenuItem = styled(CompSecaoMenuItem)`
  font-size: 1rem;
`;

export const SecaoBotaoVoltar = styled(CompSecaoBotaoVoltar)`
  font-size: 1rem;
`;

export const Programacao = styled.div`
  margin-bottom: 2rem;
  display: inline-block;
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 477px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Botao = styled.button.attrs(({ className }) => ({
  className: `btn btn-primary ${className}`,
}))`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 9999;
  transform: translate(40%, 40%);
`;

export const DiaSemana = styled.h2`
  margin-bottom: 30px;
`;

export const ProgramacaoImagemContainer = styled.div`
  height: 10rem;
  float: left;
  width: 35%;
  position: relative;
`;

export const ProgramacaoTexto = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
  padding: 0;
  margin: 0;
`;

export const ProgramacaoTitulo = styled(ProgramacaoTexto)`
  font-weight: 800;
  text-transform: uppercase;
`;

export const ProgramacaoSpeaker = styled(ProgramacaoTexto)`
  font-size: 0.9rem;
  font-weight: 800;
  text-transform: uppercase;
`

export const ProgramacaoSubTitulo = styled(ProgramacaoTexto)`
  font-weight: 800;
`;

export const ProgramacaoHorarioLocal = styled(ProgramacaoTexto)`
  font-weight: 800;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.secondary};
`;

export const ProgramacaoConteudo = styled.div`
  margin-top: 1rem;
  font-size: 0.8rem;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.text};
`;

export const ProgramacaoConteudoContainer = styled.div`
  position: relative;
  float: left;
  width: 65%;
  padding-left: 50px;
  display: inline-block;
`;

export const ImagemResponsiva = styled(Banner)`
  display: block;
  margin-left: auto;
`;

export const DivNotRegister = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;
