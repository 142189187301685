import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from '@alweb-merkator/shared/services/api';

import { useTranslation } from 'react-i18next';
import { Tradutor } from '@alweb-merkator/shared/components/I18n';

import {
  BannerInternas,
  Col,
  Conteudo,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Iframe,
} from './styles';
import { useParams } from 'react-router';

const SecaoSerraPark = ({ idPage, ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const secaoId = t('slugs.serraPark');
  const [serraPark, setSerraPark] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGetGramdo();
    return function cleanup() {};
  }, []);

  const _handleGetGramdo = async () => {
    await api.get(`/blnews/${idPage}`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: '',
        content: '',
        image: dados.image,
        image_description: dados.image_description,
      };

      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
        dado.description = dados['description_' + lang];
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
        dado.description = dados.description;
      }
      setSerraPark(dado);
      setIsLoading(true);
    });
  };
  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? serraPark.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <SecaoSubTitulo className="mb-4">
        <Tradutor path={isLoading ? serraPark.description : ''} />
      </SecaoSubTitulo>

      <BannerInternas
        src={isLoading ? serraPark.image : ''}
        alt={isLoading ? serraPark.image_description : ''}
        className="mb-4 mt-30"
      />

      <Row>
        <Col md="6">
          <Conteudo
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: isLoading ? serraPark.content : '',
            }}
          />
        </Col>
        <Col md="6">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3475.9002636016253!2d-50.86752368489983!3d-29.402473582120333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c1ac99682b6db%3A0xb597beb79145b500!2sSerra%20Park%20-%20Centro%20de%20Feiras%20e%20Eventos!5e0!3m2!1spt-BR!2sbr!4v1615657132542!5m2!1spt-BR!2sbr"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </Col>
      </Row>
    </Secao>
  );
};
SecaoSerraPark.propTypes = {
  idPage: PropTypes.any,
};
export default SecaoSerraPark;
