import React, { useMemo } from 'react';
import RodapeFeiras from '@alweb-merkator/shared/components/RodapeFeiras';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

function Rodape() {
  const { t } = useTranslation();
  const { lang } = useParams();

  const item = (text, link, subMenus = [], externalLink = false) => ({
    text: text,
    link: link,
    subMenus,
    externalLink,
  });

  const medias = {
    appStore: '//apps.apple.com/us/app/merkator-feiras-e-eventos/id1487335018',
    googlePlay:
      '//play.google.com/store/apps/details?id=com.merkatorvisitante&hl=pt_BR&gl=US',
  };

  const linksSobreAFeira = useMemo(
    () => [
      item(t('cabecalho.sobreAFeira'), `/${lang}#${t('slugs.sicc')}`),
      item(t('cabecalho.sicc'), `/${lang}#${t('slugs.sicc')}`),
      item(t('cabecalho.gramado'), `/${lang}#${t('slugs.gramado')}`),
      item(t('cabecalho.serraPark'), `/${lang}#${t('slugs.serraPark')}`),
      item(t('cabecalho.parceiros'), `/${lang}#${t('slugs.parceiros')}`),
      item(
        t('cabecalho.grupoDeImportadores'),
        `/${lang}#${t('slugs.grupoDeImportadores')}`
      ),
      item(t('cabecalho.estacao3'), `/${lang}#${t('slugs.estacao3')}`),
      item(t('cabecalho.conectech'), `/${lang}#${t('slugs.conectech')}`),
    ],
    []
  );

  return <RodapeFeiras linksSobreAFeira={linksSobreAFeira} medias={medias} />;
}

export default Rodape;
