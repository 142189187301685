import styled from 'styled-components';

import BannerInternas from '@alweb-merkator/shared/components/BannerInternas';

export { Col, Row } from 'reactstrap';

export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '@alweb-merkator/shared/components/Secao';

export const Conteudo = styled.div``;

export const ImagemResponsiva = styled(BannerInternas)`
  display: block;
  margin-left: auto;
`;
