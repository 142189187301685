import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import blNewsService from '@alweb-merkator/shared/services/blNewsService';
import ImagemGrid1 from '../../../../assets/imagem-grid-1.png';
import ImagemGrid2 from '../../../../assets/imagem-grid-2.png';
import {
  Conteudo,
  Grid,
  GridItem,
  Secao,
  SecaoCabecalho,
  SecaoTitulo,
  Tradutor,
} from './styles';
import { useParams } from 'react-router';

const SecaoEstacao3 = ({ idPage, api, ...props }) => {
  const { t } = useTranslation();
  const secaoId = t('slugs.estacao3');
  const { lang } = useParams();
  const { getPaginas } = blNewsService();
  const [estacao, setEstacao] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGetSicc();
    return function cleanup() {};
  }, []);

  const _handleGetSicc = async () => {
    await getPaginas(idPage).then(({ items }) => {
      const dado = items && items.length ? items[0] : {};
      setEstacao(dado);
      setIsLoading(true);
    });
  };

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>
          <Tradutor path={isLoading ? estacao.title : ''} />
        </SecaoTitulo>
      </SecaoCabecalho>

      <Conteudo
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: t(isLoading ? estacao.content : ''),
        }}
      />

      <Grid to={`/${lang}/${t('slugs.galeriaDeImagens')}`}>
        <GridItem
          style={{
            backgroundImage: `url(${ImagemGrid1})`,
          }}
        />
        <GridItem
          className="mr-0"
          style={{
            backgroundImage: `url(${ImagemGrid2})`,
          }}
        />
      </Grid>
    </Secao>
  );
};
SecaoEstacao3.propTypes = {
  idPage: PropTypes.any,
  api: PropTypes.any,
};
export default SecaoEstacao3;
