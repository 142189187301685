import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Col,
  Conteudo,
  ImagemResponsiva,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Tradutor,
} from './styles';
import { useParams } from 'react-router';
import LogoImportadoes from '../../../assets/logo-importadores-en.png';

const SecaoGrupoDeImportadores = ({ idPage, api, ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const secaoId = t('slugs.grupoDeImportadores');
  const [importadores, setImportadores] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGetSicc();
    return function cleanup() {};
  }, []);

  const _handleGetSicc = async () => {
    await api.get(`/blnews/${idPage}`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: '',
        content: '',
        image: dados.image,
        image_description: dados.image_description,
      };

      console.log(lang);
      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
        dado.description = dados['description_' + lang];
        if (lang === 'en') {
          dado.image = LogoImportadoes;
        }
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
        dado.description = dados.description;
      }

      setImportadores(dado);
      setIsLoading(true);
    });
  };
  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4 mb-30">
        <SecaoTitulo>
          <Tradutor path={isLoading ? importadores.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <Row>
        <Col md="6">
          <Conteudo
            className="mb-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: t(isLoading ? importadores.content : ''),
            }}
          />
        </Col>
        <Col md="6">
          <ImagemResponsiva
            className="m-height-290"
            src={isLoading ? importadores.image : ''}
            alt={isLoading ? importadores.image_description : ''}
          />
        </Col>
      </Row>
    </Secao>
  );
};
SecaoGrupoDeImportadores.propTypes = {
  idPage: PropTypes.any,
  api: PropTypes.any,
};
export default SecaoGrupoDeImportadores;
