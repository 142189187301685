import styled from 'styled-components';
import {
  FacebookShareButton as RshFacebookShareButton,
  TwitterShareButton as RshTwitterShareButton,
  WhatsappShareButton as RshWhatsappShareButton,
} from 'react-share';
import { FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';

import {
  SecaoMenuItem as CompSecaoMenuItem,
  SecaoBotaoVoltar as CompSecaoBotaoVoltar,
  SecaoBotaoVoltarProgramacao as CompSecaoBotaoVoltarProgramacao,
} from '../../components/Secao';

export { Tradutor } from '../../components/I18n';

export { default as ImageCover } from '../../components/ImageCover';

export { HashLink } from 'react-router-hash-link';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../components/Secao';

export const ShareContainer = styled.div`
  text-align: right;
  margin: 10px 0 40px;
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 477px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const FacebookShareButton = styled(RshFacebookShareButton)`
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-width: 1px !important;
  border-radius: 50% !important;
  border-style: solid !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 5px;
`;

export const TwitterShareButton = styled(RshTwitterShareButton)`
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-width: 1px !important;
  border-radius: 50% !important;
  border-style: solid !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 5px;
`;

export const WhatsappShareButton = styled(RshWhatsappShareButton)`
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-width: 1px !important;
  border-radius: 50% !important;
  border-style: solid !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 5px;
`;

export const ProgramacaoTexto = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
  padding: 0;
  margin: 0;
`;

export const ProgramacaoImagemContainer = styled.div`
  height: 10rem;
  float: left;
  width: 35%;
  position: relative;
  margin-bottom: 30px;
`;

export const ProgramacaoHorarioLocal = styled(ProgramacaoTexto)`
  font-weight: 800;
`;

export const ProgramacaoSubTitulo = styled(ProgramacaoTexto)`
  font-weight: 800;
`;

export const ProgramacaoTitulo = styled(ProgramacaoTexto)`
  font-weight: 800;
  text-transform: uppercase;
`;

export const ProgramacaoConteudoContainer = styled.div`
  position: relative;
  float: left;
  width: 65%;
  padding-left: 50px;
  display: inline-block;
`;

export const ProgramacaoConteudo = styled.div`
  margin-top: 1rem;
  font-size: 0.8rem;
  position: relative;
  height: 100%;
  color: ${(props) => props.theme.colors.text};
`;

export const FacebookIcon = styled(FaFacebookF)`
  color: ${(props) => props.theme.colors.primary};
  size: 2rem;
`;

export const TwitterIcon = styled(FaTwitter)`
  color: ${(props) => props.theme.colors.primary};
  size: 2rem;
`;

export const WhatsappIcon = styled(FaWhatsapp)`
  color: ${(props) => props.theme.colors.primary};
  size: 2rem;
`;

export const DiaSemana = styled.h2`
  margin-bottom: 20px;
  margin-top: 60px;
`;

export const SecaoMenuItem = styled(CompSecaoMenuItem)`
  font-size: 1rem;
`;

export const SecaoBotaoVoltar = styled(CompSecaoBotaoVoltar)`
  font-size: 1rem;
`;

export const SecaoBotaoVoltarProgramacao = styled(
  CompSecaoBotaoVoltarProgramacao
)`
  font-size: 1rem;
`;

export const Titulo = styled.h2``;

export const Conteudo = styled.div`
  margin: 30px 0;
`;
