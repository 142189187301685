export default {
  title: 'sicc',

  colors: {
    primary: '#D01B8B',
    secondary: '#29017C',
    tertiary: '#f297cf',
    text: '#A6A4A7',
    textInBg: '#fff',
    borders: '#707070',
    borderBotaoEMerkator: '#ffffff',
    boxShadow: '#000000',
  },
  transparentColors: {
    primary: 'rgba(208,27,139,0.5)',
    secondary: 'rgba(41,1,124,0.7)',
  },
};
