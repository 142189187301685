import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Tradutor } from '../../../components/I18n';

import {
  Col,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Conteudo,
  Botao,
} from './styles';

function SecaoExpositores({ showCategory }) {
  const { t } = useTranslation();
  const secaoId = t('slugs.expositores');
  const { lang } = useParams();

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path="paginas.inicial.secaoExpositores.titulo" />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      {!showCategory && (
        <Conteudo
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: t('paginas.inicial.secaoExpositores.subTituloBreve'),
          }}
        />
      )}

      {showCategory && (
        <Row>
          <Col md="4">
            <Botao
              to={`/${lang}/${t('slugs.expositores')}/${t(
                'slugs.expositoresCategoriaFeminino'
              )}`}
            >
              <Tradutor path="paginas.expositores.botaoCategoria.feminino" />
            </Botao>
          </Col>
          <Col md="4">
            <Botao
              to={`/${lang}/${t('slugs.expositores')}/${t(
                'slugs.expositoresCategoriaMasculino'
              )}`}
            >
              <Tradutor path="paginas.expositores.botaoCategoria.masculino" />
            </Botao>
          </Col>
          <Col md="4">
            <Botao
              to={`/${lang}/${t('slugs.expositores')}/${t(
                'slugs.expositoresCategoriaInfantil'
              )}`}
            >
              <Tradutor path="paginas.expositores.botaoCategoria.infantil" />
            </Botao>
          </Col>
          <Col md="4">
            <Botao
              to={`/${lang}/${t('slugs.expositores')}/${t(
                'slugs.expositoresCategoriaBolsasAcessorios'
              )}`}
            >
              <Tradutor path="paginas.expositores.botaoCategoria.acessorios" />
            </Botao>
          </Col>
          <Col md="4">
            <Botao
              to={`/${lang}/${t('slugs.expositores')}/${t(
                'slugs.expositoresCategoriaEsportivos'
              )}`}
            >
              <Tradutor path="paginas.expositores.botaoCategoria.esportivos" />
            </Botao>
          </Col>
          <Col md="4">
            <Botao to={`/${lang}/${t('slugs.expositores')}`}>
              <Tradutor path="paginas.expositores.botaoCategoria.listaCompleta" />
            </Botao>
          </Col>
        </Row>
      )}
    </Secao>
  );
}

SecaoExpositores.propTypes = {
  showCategory: PropTypes.any,
};

export default SecaoExpositores;
