import styled from 'styled-components';

export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
} from '@alweb-merkator/shared/components/Secao';

export { default as BannerInternas } from '@alweb-merkator/shared/components/BannerInternas';

export const Conteudo = styled.div``;

export const BotaoLinkExterno = styled.a.attrs((props) => ({
  className: `btn btn-secondary ${props.className}`,
}))`
  display: inline-block;
  border-radius: 0.5rem;
  height: 65px;
  padding: 17.5px 30px;
  border: 0px;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const TextoBotao = styled.p`
  margin: 0;
  font-size: 0.95rem;
`;
