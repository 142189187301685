import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  BannerInternas,
  Conteudo,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Tradutor,
} from './styles';
import { useParams } from 'react-router';

const SecaoParceiros = ({ idPage, api, ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const secaoId = t('slugs.parceiros');
  const [parceiros, setParceiros] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGetParceiros();
    return function cleanup() {};
  }, []);

  const _handleGetParceiros = async () => {
    await api.get(`/blnews/${idPage}`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: '',
        content: '',
        image: dados.image,
        image_description: dados.image_description,
      };

      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
      }
      setParceiros(dado);
      setIsLoading(true);
    });
  };

  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>
          <Tradutor path={isLoading ? parceiros.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <BannerInternas
        src={isLoading ? parceiros.image : ''}
        alt={isLoading ? parceiros.image_description : ''}
        className="mb-4 mt-30"
      />
      <Conteudo
        dangerouslySetInnerHTML={{
          __html: isLoading ? parceiros.content : '',
        }}
      />
    </Secao>
  );
};
SecaoParceiros.propTypes = {
  idPage: PropTypes.any,
  api: PropTypes.any,
};
export default SecaoParceiros;
