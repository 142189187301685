const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menu',
      sobreAFeira: 'about-a-feira',
      sicc: 'sicc',
      gramado: 'Gramado',
      serraPark: 'serra-park',
      parceiros: 'partners',
      grupoDeImportadores: 'importers-group',
      estacao3: 'station-3',
      conectech: 'conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'hosting',
      traslado: 'transfer',
      credenciamento: 'accreditation',
      agenciaDeTurismo: 'agencia-de-turismo',
      expositores: 'exhibitors',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios: 'bolsas-e-acessorios',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'floor plan',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-talks',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'press',
      noticias: 'news',
      galeriaDeImagens: 'image-gallery',
      contato: 'contact',
      programacoes: 'emerkatortalks',
      onnoplace: 'onno-place',
      cupomBeneficios: 'cupom-beneficios',
    },
    cabecalho: {
      painelExpositor: 'Exhibitor Panel',
      menu: 'Menu',
      sobreAFeira: 'About the Fair',
      sicc: 'SICC',
      gramado: 'Gramado',
      serraPark: 'Serra Park',
      parceiros: 'Partners',
      grupoDeImportadores: 'Importers Group',
      estacao3: 'Station 3',
      conectech: 'Conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'Accommodation',
      traslado: 'Transfer',
      credenciamento: 'Accreditation',
      agenciaDeTurismo: 'Tourism Agency',
      expositores: 'Exhibitors',
      plantaBaixa: 'Floor Plan',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'eMerkator Talks',
      eMerkatorBlog: 'eMerkator Blog',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Press',
      noticias: 'News',
      galeriaDeImagens: 'Image Gallery',
      contato: 'Contact',
      onnoPlace: 'ONNO place',
    },
    paginas: {
      inicial: {
        secaoSicc: {
          titulo: 'SICC - International Leather and Footwear Exhibition',
          subTitulo: 'The new face of fashion',
          botaoQueroVisitar: 'I want to visit',
          botaoGaleria: 'Gallery',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Gramado',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Serra Park',
          subTitulo: 'Viação Férrea, nº 100, Gramado/RS',
          conteudo: '',
          tituloCaracteristicas: 'Features',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Partners',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Importers Group',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Station 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'I want to visit',
          hospedagem: {
            titulo: 'ACCOMMODATION',
            conteudo:
              '<p> Merkator Feiras e Eventos, promoter of SICC, will provide courtesy accommodation in hotels in Serra Gaúcha, during the period of the fair. <br/>Courtesies are exclusive to retailers in the footwear sector.</p>',
            textoBotao: 'Request courtesy here',
            textoEmBreve: 'Availabe Soon',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFER',
            conteudo:
              '<p> Transfer from Salgado Filho International Airport, in Porto Alegre - Rio Grande do Sul, accredited hotels will be accredited carried out through the official transport of the SICC, with the objective of offering greater convenience, comfort and safety to our visitors. </p>',
            textoBotao: 'Times and values here',
            textoEmBreve: 'Availabe Soon',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'ACCREDITATION',
            conteudo:
              '<p> Merkator wants to facilitate your entry to the fair. With this procedure you have a badge ready waiting for you at the window of Early Accreditation and power &aacute; enter the fair to learn about the main spring-summer launches in the lime and accessories industries in Brazil. </p>',
            textoBotao: 'Register here',
            textoEmBreve: 'Availabe Soon',
            subTextoBotao: 'Accreditation available soon',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Tourism agency',
          conteudo:
            "<p> Sunpower Turismo, &eacute; the fair's official travel agency. </p> <p> It has a team of qualified and experienced professionals, always providing a quality, personalized service, with a focus on customer satisfaction. </p> <p> We offer all travel logistic services with special discounts for the fair's participants / exhibitors. </p> <p> - Accommodation; <br /> - Passage to & eacute; area; <br /> - Car rental. </p> <p> We remind you that reservations requested in advance guarantee the best rates and conditions! </p> <p> More information & ccedil; & otilde; es, follow our contacts: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>",
          subTitulo: 'Official Tourism Agency',
        },
        secaoExpositores: {
          titulo: 'Exhibitors',
          subTitulo: 'Select the desired product:',
          subTituloBreve: 'Soon more information.',
          categorias: {
            feminino: 'Women',
            masculino: 'Men',
            infantil: 'Kids',
            acessorios: 'Heather handbags, accessories and articles',
            esportivos: 'Sport',
          },
        },
        secaoPlantaDaFeira: {
          titulo: 'Floor plan',
          description: 'Click here to download',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Connects you to the market',
          textoLinkProgramacao: 'Full schedule',
        },
        secaoImprensa: {
          titulo: 'Press',
          noticias: 'News',
          galeriaDeImagens: 'Image gallery',
          falarComImprensa: 'I want to speak to the press office',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'News',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'News',
          tituloNoticia:
            'Challenging year: promoter transits in virtual and in-person environments to hold events before and during the pandemic',
          legendaImagem:
            'Frederico at a press conference at Zero Grau 2020 - Photo: Dinarci Borges',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galleries',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'eMerkatorTalks',
          diasSemana: {
            0: 'Sunday',
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
          },
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'eMerkatorTalks',
        },
      },
      traslado: {
        titulo: 'Transfer',
        description:
          '<p>Transfer from Salgado Filho International Airport, in Porto Alegre - Rio Grande do Sul, to the selected hotels will be performed by SICC official outsourced transportation team as way to provide visitors greater comfort and safety.</p><p>The Merkator team, SICC - International Leather and Footwear Exhibition, will be at the arrival terminal coordinating the above mentioned hotel transfers.</p><p><b>Contacts:</b><br /> +55 54 99144-4261 (Whatsapp e telephone)<br />+55 54 99163-1410 (Whatsapp e telephone - General Service)<br /> +55 54 3282 5445 <br /> eventos3@brockerturismo.com.br</p>',
        textAlert:
          '<b>* The above schedules may change according to need and demand.</b>',
        textAlert2:
          'It is <b>MANDATORY</b> for the customer to appear at the official transfer team`s booth, located at registration 2 of the fair, to confirm and/or schedule the transfer time from Gramado/Canela to Porto Alegre. For more information about transfers and advance purchase, contact Catherine at +55 (51) 99651-5213.',
        textTrasnporteOficial: 'Official Transport',
        textBotaoComprar: 'Buy Tickets',
        textBotaoValores: 'Values',
        textEventoDiaUm: 'Sunday 30/06',
        textEventoDiaDois: 'Monday 01/07',
        textEventoDiaTres: 'Tuesday 02/07',
        textEventoDiaQuatro: 'Wednesday 03/07',
        textEventoDiaCinco: 'Thursday 04/07',
        textTitleDescriptionDiaUm:
          'Departure from Porto Alegre Airport to the Fair&#39;s partner hotels',
        textDescriptionDiaUm: '<b>10am | 12pm | 14pm | 16pm | 18pm| 20pm *</b>',
        textTitleDescriptionDiaDois:
          'Departure from Porto Alegre Airport to the fair.',
        textDescriptionDiaDois: '<b> 8am | 10am | 12pm | 14pm*</b>',
        textTitleDescriptionDiaTres:
          'Departure from Porto Alegre Airport to the fair.',
        textTitleDescriptionDiaTresV: 'Gramado from Aeroporto Salgado Filho',
        textDescriptionDiaTres: '<b>8am | 10am | 12pm | 14pm*</b>',
        textDescriptionDiaTresV: '<b>2pm</b>',
        textTitleDescriptionDiaQuatro:
          'Gramado to Salgado Filho Airport (Boarding at the fair)',
        textTitleDescriptionDiaQuatroV:
          'Gramado/Canela para Aeroporto Salgado Filho',
        textDescriptionDiaQuatro: '<b>10am | 12pm | 2pm | 4pm</b>',
        textDescriptionDiaQuatroV:
          '(Embarque na feira)<br /><br /><b>01h | 10h | 11h30min | 13h | 14h | 15h30min</b><br /><br />Todos os horários de retorno devem ser agendados previamente nos balcões de traslado oficial, na feira.',
        textTitleDescriptionDiaCinco:
          'Gramado to Salgado Filho Airport (Pick up at fair)',
        textDescriptionDiaCinco: '<b>10am | 12pm | 14pm | 16pm *</b>',
        titleTicketUnico: 'SINGLE <BR /> TICKET',
        titleDescriptionUnico:
          'Airport (POA) > Gramado/Canela or Gramado/Canela > Airport (POA)',
        precoTicketUnico: 'R$100,00',
        comboTicketUnico: 'ROUND <br />TRIP COMBO',
        comboDescriptionUnico:
          'Airport (POA) > Gramado/Canela and Gramado/Canela > Airport (POA)',
        precoComboTicketUnico: 'R$260,00',
        titleTicketAvulso: 'INTERNAL <br /> TRANSFER ',
        descriptionTicketAvulso:
          'Accredited hotel / Serra Park – Serra Park / accredited hotel Single Ticket (all days)',
        precoTicketAvulso: 'R$100,00',
        titleComboFeira: 'COMBO<br />FEIRA',
        descriptionComboFeira:
          'Accredited hotel / Serra Park – Serra Park / accredited hotel Single Ticket (one day)',
        precoComboFeira: 'R$100,00',
        porPessoa: 'by person',
        exceptionPrice: '',
        textCompreAqui: 'BUY TICKETS',
        descriptionValor:
          '<p> <b> Purchasing the single ticket or the combo, you get the internal transfer for free during the days of the fair.</b></p>',
      },
      expositores: {
        titulo: 'Exhibitors',
        botaoCategoria: {
          listaCompleta: 'Full list',
          feminino: 'Footwear for Women',
          masculino: 'Footwear for Men',
          infantil: 'Footwear for Kids',
          acessorios: 'Bag And Accessories',
          esportivos: 'Sports Shoes',
        },
        inputPlaceholder: 'Find the brand you want',
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Page not found.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Email',
        senha: 'Password',
        entrar: 'Enter',
        cancelar: 'Cancel',
        esqueciMinhaSenha: 'I forgot my password',
      },
      secao: {
        botaoVoltar: 'Back',
      },
      cabecalho: {
        linguagem: 'Language',
        portugues: 'Portuguese',
        ingles: 'English',
        espanhol: 'Spanish',
      },
      rodape: {
        baixeNossoApp: 'Download our app',
        disponivelNa: 'Available in',
      },
      secaoEMerkator: {
        programacaoCompleta: 'Full schedule',
        // programacaoCompleta: 'Coming soon',
      },
    },
  },
};

export default traducoes;
